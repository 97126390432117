.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to ensure it's above other content */
}

.modal-wrapper-email {
    background: white;
    padding: 20px;
    border-radius: 12px;
    max-width: 1200px; /* Set a max-width for the modal */
    width: 90%; /* Use a percentage width for responsiveness */
    z-index: 1001; /* Higher than overlay to be on top */
}

.input-title {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-content {
    width: 95%;
    height: 200px; /* Set a fixed height for the text area */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none; /* Disable resizing */
}

.instructions-input {
    width: 95%;
    height: 150px; /* Set a fixed height for the text area */
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-buttons-email {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
}

.modal-btn-email {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 16px;
    background-color: #000000; /* Adjust to match your color scheme */
    width: 120px;
    color: white;
}

.modal-btn-email:hover {
    background-color: #6e6e6e;
  }

.select-btn {
    margin-left: 8px;
    padding: 4px 8px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #000000;
}

.back-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.add-btn {
    margin-bottom: 15px; /* Add some space below the Add button */
}

.page-header-email {
    text-align: center;
    padding: 4px 4px; /* Reduced top/bottom padding, added horizontal padding */
    /* border-bottom: 1px solid #ccc; */
}

.add-recipients {
    text-align: center;
}
.footer-users {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.modal-btn-users {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 16px;
    width: 120px; 
    background-color: #000000; /* Adjust to match your color scheme */
    color: white;
}
.modal-btn-sources {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 16px;
    background-color: #000000; /* Adjust to match your color scheme */
    color: white;
}

.modal-btn-sources:hover {
    background-color: #6e6e6e;
}

.modal-btn-sources.short {
    width: 120px; 
}

.modal-btn-sources.long {
    width: 160px; 
}
/* Login.module.css */
.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.925);
  }

.languageSwitchContainer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

.languageButton {
    flex: 1;
    padding: 0.5rem;
    cursor: pointer;
    background-color: lightgray;
    border: none;
    border-radius: 5px;
    text-align: center;
  }

.languageButton.selected {
    background-color: rgb(38, 37, 37);
    color: white;
  }

  .loginForm {
    margin: 0 auto;
    padding: 2rem;
    border-radius: 5px;
    background: rgb(205, 205, 205);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 320px;
    box-sizing: border-box;
  }
  
  .input {
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #4d4c4c;
    border-radius: 4px;
  }
  
  .loginButton {
    margin-top: 1rem;
    padding: 0.5rem;
    height: 42px;
    border: none;
    border-radius: 4px;
    font-size: medium;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }

  .logoContainer {
    position: absolute;
    top: 84px;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    text-align: center;
  }
  
  .logoImage {
    width: 230px;
    height: auto;
    opacity: 0; /* Initial state for smooth fade-in */
    transition: opacity 0.5s ease-in; /* Transition effect */
  }

  .logoImage.loaded {
    opacity: 1; /* Loaded state */
  }
.chatbot-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Use the full viewport height */
    width: 100%; /* Optional: Use the full viewport width if desired */
    justify-content: space-between; /* Distributes space between items */
    overflow: hidden; /* Prevents overflow */
    background-color: #ffffff;
  }
  
  .chatbot-header {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 180px;
  }

  #chat-window {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 120px;
    padding-right: 320px;
    padding-left: 320px;
    display: flex;
    justify-content: center;
  }

  #chat-window-content {
    flex-grow: 1;
    min-width: 600px;
  }
  
  .chat-input {
    box-sizing: border-box; /* Ensures padding and border are included in the width and height */
    width: 60%;
    min-height: 42px; /* Initial height */
    max-height: 200px; /* Max height corresponding to 5 lines */
    padding: 10px;
    border: none;
    font-size: 18px;
    border-radius: 16px;
    box-shadow: 0px 0px 10px #ddd;
    background-color: #ebebeb;
    color: #000000dd;
    overflow-y: auto; /* Scroll when height exceeds max height */
    resize: none; /* Prevent manual resizing */
  }
  
  .chat-input::placeholder { 
    color: #505050;
    opacity: 0.8;
  }

  .chat-input::-webkit-scrollbar {
    width: 14px; /* Width of the entire scrollbar */
  }
  
  .chat-input::-webkit-scrollbar-track {
    background: #404040; /* Track color */
    border-radius: 10px; /* Track border radius for rounded corners */
  }
  
  .chat-input::-webkit-scrollbar-thumb {
    background-color: #777; /* Scrollbar thumb color */
    border-radius: 10px; /* Thumb border radius for rounded corners */
    border: 2px solid #404040; /* Add some padding around thumb */
  }

  #chat-disabled {
    cursor: not-allowed;
    max-height: 40px; /* Initial height */
    background-color: #ebebeb;
    color: #a9a9a9; /* Different color to differentiate disabled state */
    font-style: italic;
    overflow: hidden; 
  }
  
  .message {
    display: flex;
    align-items: center;
    position: relative; /* For absolute positioning of the icon */
    margin-bottom: 10px;
    padding: 0 10px; /* Padding on the sides */
  }
  
  .message.user {
    justify-content: flex-end;
    padding-left: 30%;
  }
  
  .message.chatbot {
    justify-content: flex-start;
  }
  
  .message-icon {
    width: 26px;
    height: 26px;
    background-size: cover;
    position: absolute;
  }
  
  .message.user .message-icon {
    right: 10px; /* Space from the left for user messages */
    background-image: url('../../public/images/man4.png');
  }
  
  .message.chatbot .message-icon {
    left: 10px; /* Space from the right for chatbot messages */
    background-image: url('../../public/images/bot2.png');
    width: 27px;
  }
  
  .message p {
    flex-grow: 1; /* Allows text to fill the available space */
    padding: 10px;
    border-radius: 20px;
    margin-left: 50px; /* Space to not overlap the icon */
    margin-right: 50px; /* Space to not overlap the icon */
    line-height: 2;
  }
  
  .message.user p {
    /* background-color: #cfcfcf; */
    background-color: #ebebeb;
    color: #000000;
    margin-right: 15px; /* Space for the icon */
  }
  
  .message.chatbot p {
    background-color: #fffefe;
    color: #000;
    margin-left: 15px; /* Space for the icon */
  }
  
    .action-button {
      background-color: #90ee90; /* Adjust to match your color scheme */
      color: black; /* Text color */
      border: none;
      border-radius: 4px;
      padding: 6px 12px;
      margin-right: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .action-button:hover {
      background-color: #7edc81; /* Darker shade for hover effect */
    }
    
    /* Specific styles for user and chatbot buttons */
    .action-button.user {
      background-color: #add8e6; /* Adjust to user message color */
    }
    
    .action-button.user:hover {
      background-color: #9acbe3; /* Darker shade for user button hover */
    }
    
    .action-button.chatbot {
      background-color: #90ee90; /* Adjust to chatbot message color */
    }
    
    .action-button.chatbot:hover {
      background-color: #66c17a; /* Darker shade for chatbot button hover */
    }

    .page-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      text-align: center;
      padding: 10px 20px; /* Reduced top/bottom padding, added horizontal padding */
      border-bottom: 1px solid #ccc;
    }
    
    .page-header {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin: 0; /* Reset margin for consistency */
    }

    .page-header-top {
      position: absolute;
      top: 80px;
      left: 50%;
      font-size: 1.5em;
      font-family: 'Nunito', Arial, sans-serif;
      transform: translateX(-50%);
      margin: 0; /* Reset margin for consistency */
      color: #E6E6E6; /* Darker text for contrast */
    }
    
    .page-header-placeholder {
      flex: 1;
    }

    .page-header-container h1 {
      margin: 2px 0 2px 0; /* Bottom margin to create space above the line */
      padding: 0; /* No additional padding needed */
      font-family: 'Arial', sans-serif; /* Example font, choose what you like */
      font-size: 1.6em; /* Example size, adjust as desired */
      font-weight: bold; /* Bold text */
      color: #000000; /* Darker text for contrast */
    }
  
    .email-form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: fixed;
      bottom: 0;
      padding: 20px 0 10px 0; /* Adjust top and bottom padding for spacing */
      margin-bottom: 10px;
    }
    
    .email-form-buttons {
      display: flex;
      gap: 32px; /* Space between buttons */
      margin: 10px 0 20px; /* Add some space above and below buttons */
      flex-wrap: wrap; /* Allow wrapping to next line on smaller screens */
    }
    
    .left-container,
    .right-container {
      flex: 0 0 auto; /* Ensure these containers do not grow or shrink */
    }
    
    .center-container {
      flex: 1;
      display: flex; /* Add this to align content inside it */
      align-items: center; /* Vertically centers the header text within the container */
      justify-content: center; /* Centers the header text horizontally */
    }
    
    .page-header {
      margin: 0; /* Reset margin */
    }

    
    .options-btn,
    .sign-out-btn {
      background-color: #007bff; /* Adjust button color as needed */
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-radius: 12px;
      font-size: 1rem;
      font-weight: bold;
      width: 120px;
    }

    .options-btn:hover,
    .sign-out-btn:hover {
      background-color: #007bff;
    }
  
    .email-form-inputs {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
  
    .source-dropdown {
      flex: 1;
      padding: 10px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  
    .input-field {
      flex: 2; /* Takes twice the space of the dropdown */
      padding: 10px;
      margin-right: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .email-btnused {
      font-weight: bold;
      padding: 10px 40px;
      margin-right: 0px;
      border: none;
      border-radius: 16px;
      background-color: #007bff;
      color: rgb(255, 255, 255);
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  
    .email-btn {
      font-weight: bold;
      padding: 10px 40px;
      margin-right: 0px;
      border: none;
      border-radius: 16px;
      background-color: #2b2b2b;
      color: rgb(255, 255, 255);
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    .email-btn.ar {
      width: 210px; /* Width for Arabic language */
    }
    
    .email-btn.en {
      width: 200px; /* Width for English language */
    }
  
    .email-btn:hover {
      background-color: #828282; /* Darker shade of green for hover effect */
    }
  
    @media (max-width: 768px) {
      .email-form-inputs, .email-form-buttons {
        flex-direction: column;
        align-items: stretch;
      }
  
    .source-dropdown, .input-field, .email-btn {
      flex: 0 0 100%; /* Make sure each element takes the full width */
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .email-btn {
      width: auto; /* Allow the button to shrink to fit its content */
    }
  
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000; /* Ensure it's above other content */
    }
    
    .modal-content {
      background: white;
      padding: 20px;
      border-radius: 5px;
      width: 80%; /* Adjust width as necessary */
      max-width: 500px; /* Set a maximum width */
    }
    
    .instructions-input {
      width: 100%;
      min-height: 150px; /* Adjust height as necessary */
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .modal-btn {
      padding: 10px 20px;
      margin-right: 10px;
      border: none;
      border-radius: 4px;
      background-color: #4CAF50; /* Adjust to match your color scheme */
      color: white;
      cursor: pointer;
    }
    
    .modal-btn:hover {
      background-color: #45a049; /* Darker shade for hover effect */
    }
  }

  .fab {
    position: fixed;
    bottom: 10px;
    right: 20px;
    /* z-index: 1000; Ensure it floats above other elements */
  }

  .fabUpload {
    position: fixed;
    bottom: 10px;
    right: calc(20% - 80px);
    /* z-index: 1000; Ensure it floats above other elements */
  }

  .fabUsermanagement {
    position: fixed;
    top: 0px;
    right: 140px;
    /* z-index: 1000; Ensure it floats above other elements */
  }

  .fab-button {
    color: rgb(121, 120, 120); /* Icon and text color */
    border: none;
    border-radius: 50%;        /* Rounded button */
    width: 56px;               /* Size of the button */
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); Subtle shadow */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transition for hover effect */
  }

  .fab-button .MuiSvgIcon-root {
    font-size: 32px; /* Size of the icon */
  }

  .fab-button:hover {
    background-color: #323232;
  }

  .fab-button:active {
    background-color: #323232;
  }

  .role-label.user {
    font-weight: bold;
    color: black;
    margin-bottom: 5px;  /* Adjust spacing below the label */
    text-align: right;  /* Align text to the left */
    margin-right: 20px;  /* Adjust spacing to the right */
  }

  .role-label.chatbot {
    font-weight: bold;
    color: black;
    margin-bottom: 5px;  /* Adjust spacing below the label */
    text-align: left;  /* Align text to the left */
    margin-left: 20px;  /* Adjust spacing to the right */
  }
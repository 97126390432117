.dialog-context {
  padding-top: 4px !important;
  padding-right: 24px !important;
  padding-bottom: 20px !important;
  padding-left: 24px !important;
}

.dialog-action {
  padding-bottom: 16px !important;
}

.page-header-schedule {
  text-align: center;
  max-height: 42px;
}

.customH6 {
  font-size: 16px;
} 

.footer-schedule {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-btn-schedule {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 16px;
  width: 120px; 
  background-color: #000000; /* Adjust to match your color scheme */
  color: white;
}

.modal-btn {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.modal-btn:hover {
    background-color: #4CAF50;
  }

.modal-btn:disabled {
  background-color: #A5D6A7; /* Optional: Change color when disabled */
  cursor: not-allowed;
}
/* EditInstructionsModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to ensure it's above other content */
  }
  
  .modal-wrapper {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px; /* Set a max-width for the modal */
    width: 90%; /* Use a percentage width for responsiveness */
    z-index: 1001; /* Higher than overlay to be on top */
  }
  
  .instructions-input {
    width: 95%;
    height: 150px; /* Set a fixed height for the text area */
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-btn {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50; /* Adjust to match your color scheme */
    color: white;
  }